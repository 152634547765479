export default {
  setAction(state, { action, actionProperties = {} }) {
    state.action = action;
    state.actionProperties = actionProperties;
  },
  clearAction(state) {
    state.action = null;
    state.actionProperties = null;
  },
};
