import { createLogger, createStore } from "vuex";

import { getEnv, singleton } from "@solvari/utils";

import layout from "@/vue/apps/admin/store/modules/layout/index.js";

const store = singleton(() =>
  createStore({
    state: {
      xdebug: null,
      isDevelop: getEnv().config.isDev,
    },
    getters: {
      isDebug: (state) => state.xdebug !== null,
      isDevelop: (state) => state.isDevelop,
    },
    mutations: {
      toggleDebug(state) {
        if (state.xdebug === null) {
          state.xdebug = "PHPSTORM";
        } else {
          state.xdebug = null;
        }
      },
    },
    modules: {
      layout,
    },
    strict: true,
    plugins: getEnv().config.isDev ? [createLogger()] : [],
  }),
);

export default store;
